.content-modal {
  margin: 0 0 auto auto;
  align-items: flex-end;
  max-width: calc(100vw - 250px);
  height: calc(100vh - (70px + 60px));

  > .modal-content {
    height: calc(100vh - (70px + 55px));
    border-radius: 0;
  }
}

.content-modal-content {
  height: 1px;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-wrap: break-word;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 50px;
  }
}

.content-wrap {
  .fade {
    overflow: hidden;
  }
}

.expand-cell-header {
  text-align: center;
  line-height: 1.5rem;
  background-color: #50a5f1 !important;
  color: #fff;
}
