.shimmer {
  background-color: #f6f6f6;
  border-radius: 5px;
  animation: shimmer 2s alternate-reverse infinite;
}

.board-shimmer {
  display: flex;
  gap: 1em;
  padding: 1em;
  width: 100%;
  height: 65vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.board-shimmer::-webkit-scrollbar {
  height: 10px;
}

.board-shimmer::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 50px;
}

.shimmer-column {
  background-color: #f6f6f6;
  width: 20%;
  border-radius: 4px;
  animation: shimmer 2s alternate-reverse infinite;
}

.small-shimmer {
  height: 30%;
}

.medium-shimmer {
  height: 60%;
}

.big-shimmer {
  height: 100%;
}

@keyframes shimmer {
  0% {
    background-color: #f8f9fa;
  }
  50% {
    background-color: #f6f6f6;
  }
  100% {
    background-color: #adb5bd;
  }
}
