.text-curtain {
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrapper {
    @extend .text-curtain;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  &-field {
    @extend .text-curtain;
    padding-top: (1rem * 0.25);
    padding-bottom: (1rem * 0.25);
    padding-right: (1rem * 0.5);
    padding-left: (1rem * 0.5);
    background-color: #efefef;
    border-radius: 3px;
    min-height: 1em;
    max-height: 2em;
  }
}

.text-curtain-title {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: (1rem * 0.25);
  margin-left: 0;
  font-family: 'Poppins' sans-serif;
  font-size: 1.1em;
}

.text-curtain-icon {
  cursor: pointer;
  font-size: 1.5em;
  color: #808080;
}

.text-curtain-text {
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 0;
  color: #808080;

  &-hidden {
    @extend .text-curtain-text;
    letter-spacing: 0.5em;
    font-weight: bold;
    color: #c1c1c1;
  }
}

.can-select {
  user-select: all;
}

.cant-select {
  user-select: none;
}
