// Modal
.modal {
  &--header {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  &--icon {
    color: rgba(52, 195, 143, 1);
    font-size: 1.5em;
    margin-right: 4px;
  }

  &--title {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &--text {
    font-family: Poppins;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
  }

  &--button-one {
    background: #596fdf;
    color: white;
    border: none;
    width: 103px;
    height: 36px;
    border-radius: 4px;
  }

  &--button-two {
    background: #eff2f6;
    color: black;
    border: none;
    width: 103px;
    height: 36px;
    border-radius: 4px;
  }
}
