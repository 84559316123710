.drag-n-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ced4da;
  border-radius: 4px;
  min-height: 15vh;
  cursor: pointer;

  &:hover {
    border: 2px dashed #596fde;
    color: #596fde;

    i {
      color: #596fde;
    }
  }
}

.file-list {
  display: flex;
  flex-direction: column;
  margin-top: 5px !important;
}

.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.file p {
  margin: 0;
  font-size: 1.12em;
}
