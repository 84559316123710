.meeting {
  &-header {
    margin: 0 0 1em 0;
  }

  &-description {
    display: flex;
    flex-direction: column;
    margin: 0 0 1em 0;
    word-wrap: break-word;
  }

  &-related-tasks {
    margin: 0 0 1em 0;
  }
}

.mtheader {
  &-title {
    font: normal 600 1.2rem 'Poppins', sans-serif;
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.1em;
    margin: 0 0 0.7em 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5em;
    }

    span {
      font: normal normal 0.8rem 'Poppins', sans-serif;
      line-height: 1;
      color: #7b919e;
    }
  }

  &-participants {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.7em 0;

    span:first-child {
      margin: 0 0 0 0.5em;
    }

    span {
      font: normal normal 0.8rem 'Poppins', sans-serif;
      line-height: 1;
      color: #7b919e;
    }
  }
}

.mtsteps {
  &-title {
    font: normal 600 1rem 'Poppins', sans-serif;
    margin: 0 0 0.7em 0;
  }

  &-table {
    width: 100%;
    text-align: left;

    thead {
      border-bottom: 1px solid #eff2f7;
    }

    thead th {
      background: #ffffff;
      font-size: 0.9rem;
      font-weight: 600;
      color: #000000;
      text-align: left;
    }

    td,
    th {
      padding: 0.4rem;
    }

    td[data-count],
    th[data-count] {
      width: 5%;
      font-weight: 600;
      color: #000000;
      text-align: center;
    }

    td[data-member],
    th[data-member] {
      width: 30%;
    }

    td[data-text],
    th[data-text] {
      width: 50%;
    }

    td[data-link] {
      width: 15%;
      padding-right: 1.2rem;
      text-align: right;

      a {
        color: #7367f0;
        font-weight: 600;
        font-size: 0.85rem;
      }
    }

    tr:nth-child(odd) {
      background: #f8f9fa;
    }
  }
}

.mttask {
  &-title {
    font: normal 500 0.85rem 'Poppins', sans-serif;
    margin: 0 0 0.7em 0;
  }

  &-list {
    font: normal 500 0.85rem 'Poppins', sans-serif;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 0.6rem;

      a {
        color: #50a5f1;
      }
    }
  }

  &-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.6rem;
    border-radius: 4px;
    background-color: #dee0e2;
  }
}

.month-detail {
  margin-top: 1em;
  color: #74788d;
  user-select: none;
}

.month-summary {
  margin-bottom: 0.6em;
  font-size: 0.85rem;
  font-weight: 500;
}

.detail-meeting {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 0.6em 1em;
  margin-top: 1em;
}

.detail-link {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: #50a5f1;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;

  &:hover {
    color: #4458b8;
  }
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 0.8rem;
  line-height: 0.8rem;

  &:hover {
    fill: #4458b8 !important;
  }

  &.active-tab {
    fill: #556ee6 !important;
  }
}

.active-tab {
  border: 0 !important;
  margin: 0 !important;
  color: #556ee6 !important;
  border-bottom: 1px solid #556ee6 !important;
}
