// Kanban Column Width
.borad-width {
  min-width: 350px;
}

@media (max-width: 1024px) {
  .borad-width {
    min-width: 300px;
  }
}

.card-responsive {
  min-width: 350px !important;
  max-width: 1000px !important;
  width: calc((100vw - 400px) / 4);
}

// React-Kanban Component Class Override
.react-kanban-board {
  position: relative;
  height: 100%;
  overflow-x: auto;
}

.react-kanban-board::-webkit-scrollbar {
  height: 10px;
}

.react-kanban-board::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 50px;
}

.react-kanban-column {
  background-color: $light !important;
  height: auto;
  max-height: 62vh;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.react-kanban-column::-webkit-scrollbar {
  width: 10px;
}

.react-kanban-column::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin-right: 1em;
  visibility: hidden;
}

.react-kanban-column:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin-right: 1em;
  visibility: visible;
}

// Kanban Card
.second-row {
  margin-bottom: 5px;
  color: #adb5bd;
}

.third-row {
  display: flex;
  margin: auto 0;
  width: 60%;
  justify-content: flex-end;
}

.avatar-element {
  display: flex;
  justify-content: space-between;
}

// Kanban Status
.status {
  color: rgba(116, 120, 141, 1) !important;
  align-self: center;

  &--waiting {
    background-color: rgba(116, 120, 141, 0.2) !important;
  }

  &--prioritized {
    background-color: rgba(80, 165, 241, 0.2) !important;
  }

  &--development {
    background-color: rgba(151, 80, 241, 0.2) !important;
  }

  &--pendency {
    background-color: rgba(241, 177, 80, 0.2) !important;
  }

  &--finished {
    background-color: rgba(80, 241, 135, 0.2) !important;
  }
}

// Kanban Priority
.priority {
  color: white;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  &--low {
    background-color: rgba(211, 211, 211, 1);
  }

  &--medium {
    background-color: rgba(80, 165, 241, 1);
  }

  &--high {
    background-color: rgba(244, 106, 106, 1);
  }
}

.priority-element {
  margin-right: 10px;
  display: flex;
}

// Error
.error {
  &--message {
    color: red;
  }

  &--input {
    border-color: red !important;
    border-width: 2px !important;
  }
}

// Other
.info {
  &--text {
    margin-left: 10px;
  }

  &--row {
    margin-top: 5px;
  }
}

.markdown-error {
  border: 2px solid red;
}

.show-more-button {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  color: #556ee6;
  background-color: white;
  border: 1px solid #556ee6;
  border-radius: 4px;
  padding: 0.7em;
}

.show-more-button > i {
  font-size: 1.5em;
}

.markdown-description {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.markdown-description.show-more {
  position: relative;
  width: 100%;
  max-height: 25vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.markdown-description-fade {
  display: none;
}

.markdown-description-fade.show-more {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 7vh;
  position: absolute;
  top: calc(100% - 7vh);
}

.modal-task {
  min-height: 70vh;
  overscroll-behavior: contain;

  &-content {
    height: 47vh;
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-wrap: break-word;
  }

  &-content::-webkit-scrollbar {
    width: 10px;
  }

  &-content::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 50px;
  }
}

// Comments
.comment-wrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: rgba(116, 120, 141, 0.1);
  padding: 1rem 1rem;
}

.comment-title {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  padding: calc(1rem * 0.25) calc(1rem * 0.25);
}

.comment-title > i {
  margin-right: calc(1rem * 0.5);
  font-size: 1.5em;
  color: #c1c1c1;
}

.comment-title > h5 {
  margin: 0;
  font-weight: 600;
}

.comment-scroll {
  display: flex;
  position: relative;
  flex-direction: column;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  max-height: 48vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: (1rem * 0.5);
  margin-bottom: 1rem;
}

.comment-scroll::-webkit-scrollbar {
  width: 10px;
}

.comment-scroll::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 50px;
}

.comment-message {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 1rem;
}

.com-message-option {
  display: flex;
  height: fit-content;
  font-size: 1.2em;
  font-weight: lighter;
  margin-left: calc(1rem * 0.25);
}

.com-message-option > button {
  border: 0;
  margin: 0;
  padding: 0;
}

.comment-box {
  width: 90%;
  padding: calc(1rem * 0.75) calc(1rem * 1.25);
  border-radius: 8px 8px 8px 0;
}

.com-box-title {
  font-weight: bold;
  color: #556ee6;
  margin-bottom: calc(1rem * 0.25);
}

.com-box-content {
  color: #495057;
  margin-bottom: calc(1rem * 0.25);
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 90%;
}

.com-box-content-edit {
  border: 1px solid #495057;
  border-radius: 4px;
  padding: 0.3em;
}

.com-box-date {
  display: flex;
  align-items: center;
  color: #a6a6a6;
}

.com-box-date > p {
  margin: 0;
  margin-left: calc(1rem * 0.5);
}

.self-box {
  background-color: #ffffff;
}

.others-box {
  background-color: #556ee61a;
}

.comment-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  min-height: 2rem;
  width: 100%;
  height: auto;
}

.comment-input > textarea {
  box-sizing: border-box;
  height: 2rem;
  width: 100%;
  margin: 0 0.5em;
  padding: 0.5em 1.5em;
  background-color: #e6eaf2;
  color: #74788d;
  border: none;
  border-radius: 1rem;
  resize: none;
  overflow: auto;
}

.comment-input > textarea::-webkit-scrollbar {
  display: none;
}

.comment-input > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  gap: 0.4em;
  margin: 0 0.5em;
  padding: 0 1em;
  background-color: #556ee6;
  color: #ffffff;
  border: 0;
  border-radius: 50px;
  font-weight: lighter;
}

.menu-shift {
  margin-left: -145px;
}

.no-comment {
  background-color: #eeeeee;
  color: #3c3e49;
  width: 100%;
  border-radius: 4px;
  padding: 0.7em 1.2em;
}

// Checklist
.custom-checklist {
  font-size: 1em;
  margin: 0;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em 1fr;
  gap: 0.5em;
}

.custom-checklist + .custom-checklist {
  margin-top: 0.5em;
}

.custom-checklist > input {
  // Remove native checkbox styling
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  // Change cursor on hover
  cursor: pointer;
  // Customize Unchecked box
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.2em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  // Align checked box
  display: grid;
  place-content: center;
}

.custom-checklist > input::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  box-shadow: inset 1em 1em black;
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.custom-checklist > input:checked::before {
  transform: scale(1);
}

.custom-checklist > input:focus {
  outline: calc(max(2px, 0.15em)) solid rgba(0, 0, 0, 0.3);
  outline-offset: calc(max(1px, 0.1em));
}

.custom-checklist > input:disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

.check-div .chk-list-option {
  opacity: 0;
}

.check-div:hover .chk-list-option {
  opacity: 50%;
}

.new-check {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  line-height: 1.1;
  margin-top: 0.7em;
  color: #adb5bd;
}

.new-check > input {
  color: inherit;
  border: none;
}

.new-check > i {
  font-size: 1.2em;
}
