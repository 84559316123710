.log-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &-wrap {
    width: 35vw;
    max-height: 50vh;
  }

  &-header {
    @extend .log-modal;
    justify-content: flex-start;
    width: 100%;
  }

  &-title {
    @extend .log-modal;
    gap: 1em;

    > p {
      margin: 0;
    }

    > i {
      font-size: 1.4em;
    }
  }

  &-close {
    @extend .log-modal;
    padding: 0;
    font-size: 2em;
    margin-left: auto;
    border: 0;
    background-color: white;
  }

  &-body {
    font-family: 'Poppins' sans-serif;
    font-size: 1em;
    font-weight: 500;
    max-height: 35vh;
    overflow-y: auto;
  }

  &-version {
    font-family: 'Poppins' sans-serif;
    font-size: 1em;
    font-weight: 500;
  }

  &-list {
    padding: 0;
  }

  &-item {
    @extend .log-modal;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    list-style-type: none;
    margin-bottom: 0.6em;

    > span {
      font-family: 'Poppins' sans-serif;
      font-size: 1em;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
}

.log-modal-body::-webkit-scrollbar {
  width: 10px;
}

.log-modal-body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 50px;
}
