.wmde-markdown {
  color: inherit !important;
  background-color: white !important;
  --color-border-default: #ced4da !important;

  code {
    background-color: #f0f1f2 !important;
  }

  pre {
    code {
      background-color: transparent !important;
      color: #f0f1f2 !important;
    }
  }
}

.w-md-editor {
  color: inherit !important;
  background-color: white !important;
  --color-border-default: #ced4da !important;
}

.w-md-editor-toolbar {
  background-color: white !important;
  --color-fg-default: #0d1117 !important;
}
