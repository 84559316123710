.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.task-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &-wrapper {
    @extend .task-summary;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-right: 1em;
    padding-left: 1em;
    margin-right: 10px;
    margin-left: 10px;
    background-color: white;
    width: 100%;
    min-height: 9em;
    border-radius: 4px;
    margin-bottom: 1em;
  }

  &-stats {
    @extend .task-summary;
  }

  &-stat {
    margin-right: 3.5em;
  }

  &-stat:nth-child(1) {
    margin-right: 3.5em;
    margin-left: 2em;
  }

  &-title {
    color: #74788d;
    font-family: 'Poppins' sans-serif;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
  }

  &-number {
    color: #495057;
    font-family: 'Poppins' sans-serif;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0;
  }

  &-logo {
    display: none;
    background-clip: padding-box;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    width: 5em;
    height: 5em;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
  }

  &-loading-icon {
    font-size: 2rem;
    color: #d2d2d2;
    display: inline;
    animation: spin 1s linear infinite;
    margin-right: 1.5vw;
    margin-left: 1.5vw;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;

  &-icon {
    @extend .filter;
    gap: 1em;
    margin-left: auto;
    margin-right: 2.5em;
    font-family: 'Poppins' sans-serif;
    font-size: 1.2em;
    font-weight: 500;
    cursor: pointer;

    > box-icon {
      font-size: 1.5em;
      font-weight: 800;
    }
    > p {
      margin: 0;
      user-select: none;
    }
  }

  &-buttons {
    @extend .filter;
    gap: 1em;
    margin-left: auto;
    margin-right: 2.5em;
    font-family: 'Poppins' sans-serif;
    font-size: 1.2em;
    font-weight: 500;
  }

  &-btn {
    @extend .filter;
    line-height: 1.5em;
    border: 0;
    border-radius: 4px;
    padding: 0.5em 1em;
    font-family: 'Poppins' sans-serif;
    font-size: 1em;
    font-weight: 500;
    color: white;

    > box-icon {
      font-size: 1.5em;
      font-weight: 500;
    }
  }

  &-menu {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    width: 100%;
    margin-right: 2em;

    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;

      .search {
        width: 100%;
        position: relative;

        > box-icon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        > input {
          padding-left: 35px;

          &::placeholder {
            color: #7b919e;
          }
        }
      }
    }
  }

  &-select {
    min-width: 150px;
    max-width: 25%;
    flex-grow: 1;
  }
}
