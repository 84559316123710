.loading-pfp {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
  height: 2rem;
  width: 2rem;
  background-color: #f6f6f6;
  animation: loading-img 2s alternate-reverse infinite;
}

.remove-file-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  color: #f46a6a;
  padding: 0;
  margin: 0;
  background-color: white;
  border: 0;
}

.upload-photo-default-caption {
  margin-top: 4px;
  color: #74788d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.loading-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-img {
  0% {
    background-color: #f8f9fa;
  }
  50% {
    background-color: #f6f6f6;
  }
  100% {
    background-color: #adb5bd;
  }
}
